import "bootstrap/dist/css/bootstrap.min.css"
import LandingPage from "./pages/LandingPage"
import PreOrder from "./pages/PreOrder";
import Articles from "./pages/Articles";
import Login from "./pages/Login";
import Article from "./pages/Article";
import ArticlePanel from "./components/articles/ArticlePanel";
import PublishArticle from "./components/articles/PublishArticle";
import UpdateArticle from "./components/articles/UpdateArticle";
import { BrowserRouter, Routes,  Route} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';
import Signup from './pages/Signup';
import Activate from './pages/Activate';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordConfirm from './pages/ResetPasswordConfirm';
import AdminUsersPage from "./pages/AdminPages/AdminUsersPage";
import AdminHomePage from "./pages/AdminPages/AdminHomePage";
import AdminDocumentsPage from "./pages/AdminPages/AdminDocumentsPage";
import ForbiddenPage from "./pages/PageNotAcces";
import ContactUs from "./pages/ContactUs";
import ProductPage from "./pages/AdminPages/AdminProductPage";
import NotFound from "./pages/PageNotFound";
import PreOrderPanel from "./components/preOrder/PreOrdersPanel";
import Products from "./pages/Products";
import Careers from "./pages/Careers";
import Job from "./pages/Job";
import Prices from "./pages/Prices";
import AboutUs from "./pages/AboutUs";
import Solutions from "./pages/Solutions";
import Layout from "./hocs/Layout";
import PrivacyPolicy from "./pages/policies/PrivacyPolicy";
import TermofServices from "./pages/policies/TermofServices";
import CookiePolicy from "./pages/policies/CookiePolicy";
import RefundPolicy from "./pages/policies/RefundPolicy";
import SecurityPolicy from "./pages/policies/SecurityPolicy";
import IntellectualPropertyPolicy from "./pages/policies/IntellectualPropertyPolicy";
import AccessibilityStatement from "./pages/policies/AccessibilityStatement";
import AcceptableUsePolicy from "./pages/policies/AcceptableUsePolicy";
import LegalPage from "./pages/policies/LegalPage";
import ClientLayout from "./hocs/ClientLayout";
import ClientHomePage from "./pages/ClientHomePage";
import ClientProfile from "./pages/ClientProfile";
import ClientSettings from "./pages/ClientSettings";
import AdminPreOrdersPage from "./pages/AdminPreOrdersPage";
import XcapitalterminalPage from "./pages/Products/XcapitalterminalPage"
import XcapitaldataPage from "./pages/Products/XcapitaldataPage";
import XcapitalarticlesPage from "./pages/Products/XcapitalarticlesPage";
import CheckoutPage from "./pages/CheckoutPage";
import { CartProvider } from "./Context/CartContext";
import ClientArticles from "./pages/ClientArticles";
import ClientSolutions from "./pages/ClientSolutions";
import MessagesList from "./pages/AdminPages/messages/MessagesList";
import MessagesContent from "./pages/AdminPages/messages/MessagesContent";
import MessageForm from "./pages/AdminPages/messages/MessageForm";
import AdminLayout from "./hocs/AdminLayout";
import AdminCareersPage from "./pages/AdminPages/AdminCareersPage";
import AdminCandidatsPage from "./pages/AdminPages/AdminCandidatesPage";
import AdminCategoriesPage from "./pages/AdminPages/AdminCategoriesPage";
import ManagerLayout from "./hocs/ManagerLayout";
import ManagerDashboard from "./pages/Manager/ManagerDashboard";
import ClientSupport from "./pages/ClientSupport";
import ClientSupportList from "./pages/ClientSupportList";
import PricingPage from "./pages/Pricingtes";
import Terminal from "./pages/Solution/Terminal";
import Intelligence from "./pages/Solution/intelligence";
import AdminTargetSalesPage from "./pages/AdminPages/AdminTargetSalesPage";
import CMIpolicies from "./pages/policies/CMIpolicies";
import Data from "./pages/Solution/Data";
import ArticlesSolution from "./pages/Solution/ArticlesSolution";


function App() {
  return (
    <CartProvider>
      <BrowserRouter>
        <Provider store={store}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<LandingPage />} />
              <Route path="/wait-list" element={<PreOrder />} />
              <Route path="/articles" element={<Articles />} />
              <Route path="/article" element={<Article />} />
              <Route path="/article/:articleId" element={<Article />} />
              <Route path="article-post" element={<PublishArticle />} />
              <Route
                path="article/:articleId/update"
                element={<UpdateArticle />}
              />
              <Route path="pre-order-panel" element={<PreOrderPanel />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/x-capital/terminal/admin/login"
                element={<Login />}
              />
              <Route path="/signup" element={<Signup />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/password/reset/confirm/:uid/:token"
                element={<ResetPasswordConfirm />}
              />
              <Route path="/activate/:uid/:token" element={<Activate />} />
              <Route path="/products" element={<Products />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/career/:jobId" element={<Job />} />
              <Route path="/solutions/terminal" element={<Terminal />} />
              <Route
                path="/solutions/intelligence"
                element={<Intelligence />}
              />
              <Route
                path="/solutions/data"
                element={<Data />}
              />
              <Route
                path="/solutions/articles"
                element={<ArticlesSolution />}
              />

              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/solutions" element={<Solutions />} />
              <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/legal/term-of-service"
                element={<TermofServices />}
              />
              <Route path="/legal/cookie-policy" element={<CookiePolicy />} />
              <Route
                path="/legal/refund-cancellation-policy"
                element={<RefundPolicy />}
              />
              <Route
                path="/legal/security-policy"
                element={<SecurityPolicy />}
              />
              <Route
                path="/legal/intellectual-property-policy"
                element={<IntellectualPropertyPolicy />}
              />
              <Route
                path="/legal/accessibility-statement"
                element={<AccessibilityStatement />}
              />
              <Route
                path="/legal/CMI-policies"
                element={<CMIpolicies />}
              />
              <Route
                path="/legal/acceptable-use-policy"
                element={<AcceptableUsePolicy />}
              />
              <Route path="/legal" element={<LegalPage />} />
              <Route
                path="/solutions/x-capital-terminal"
                element={<XcapitalterminalPage />}
              />
              <Route
                path="/solutions/x-capital-data"
                element={<XcapitaldataPage />}
              />
              <Route
                path="/solutions/x-capital-articles"
                element={<XcapitalarticlesPage />}
              />
              <Route path="/checkout" element={<CheckoutPage />} />
            </Route>
        <Route path="/x-capital/terminal/admin" element={<AdminLayout />}>
            <Route index element={<AdminHomePage/>}/>
            <Route path="messages" element={<MessagesList/>}/>
            <Route path="messages/:id" element={<MessagesContent/>}/>
            <Route path="messages/send" element={<MessageForm/>}/>
            <Route path="users" element={<AdminUsersPage/>}/>
            <Route path="documents" element={<AdminDocumentsPage/>}/>
            <Route path="products" element={<ProductPage />}/>
            <Route path="articles" element={<AdminUsersPage/>}/>
            <Route path="category" element={<AdminCategoriesPage />} />
            <Route path="article-panel" element={<ArticlePanel />} />
            <Route path="article-post" element={<PublishArticle />} />
            <Route
              path="article/:articleId/update"
              element={<UpdateArticle />}
            />
            <Route path="article/:articleId/update" element={<UpdateArticle />} />
            <Route path="pre-orders" element={<AdminPreOrdersPage />} />
            <Route path="careers" element={<AdminCareersPage />} />
            <Route path="candidates" element={<AdminCandidatsPage />} />
            <Route path="pre-order-panel" element={<PreOrderPanel />} />
            <Route path="target-revenue" element={<AdminTargetSalesPage />} />
        </Route>        
            
            

            
            <Route path="/x-capital/terminal/client" element={<ClientLayout/>}>
                <Route index element={<ClientHomePage/>}/>
                <Route path="profile" element={<ClientProfile/>}/>
                <Route path="settings" element={<ClientSettings/>}/>
                <Route path="articles" element={<ClientArticles/>}/>
                <Route path="solutions" element={<ClientSolutions/>}/>
                <Route path="messages">
                  <Route index element={<MessagesList/>}/>
                  <Route path="send" element={<MessageForm/>}/>
                  <Route path=":id" element={<MessagesContent/>}/>
                </Route>
                <Route path="support">
                  <Route index element={<ClientSupport/>}/>
                  <Route path="list" element={<ClientSupportList/>}/>
                </Route>
            </Route>
            <Route
              path="/x-capital/terminal/manager"
              element={<ManagerLayout />}
            >
              <Route index element={<ManagerDashboard />} />
              <Route path="users" element={<AdminUsersPage />} />
              <Route path="pre-orders" element={<AdminPreOrdersPage />} />
              <Route path="documents" element={<AdminDocumentsPage />} />
              <Route path="messages" element={<MessagesList />} />
              <Route path="messages/:id" element={<MessagesContent />} />
              <Route path="messages/send" element={<MessageForm />} />
              <Route path="products" element={<ProductPage />} />
              <Route path="article-panel" element={<ArticlePanel />} />
              <Route path="article-post" element={<PublishArticle />} />
            </Route>

            <Route path="*" element={<NotFound />} />
            <Route path="/Not-Access" element={<ForbiddenPage />} />
          </Routes>
        </Provider>
      </BrowserRouter>
    </CartProvider>
  );
}

export default App;