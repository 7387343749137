import {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import fetchArticlesTeasers from "../services/api/artilcesAPI/fetchArticleTeaser";
import {
    Flex,
    Text,
    Button,
    Image,
    Heading,
    useMediaQuery,
    Box,
    Center,
    IconButton,
    Stack
} from "@chakra-ui/react";
import {ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";
import Questions from "../components/questions/Questions";
import ProductsSection from "../components/products/ProductsSection";
import xcapitalbanner from "../assets/xcapitalbanner.jpg";
import {Autoplay, Navigation} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import {Swiper, SwiperSlide} from "swiper/react";
import TradingViewTicker from "../components/TradingViewTicker";

const partnersLogosPath = "/images/partners/"

const partners = [
    {logoName: "bank_al_maghrib.png", alt: "Bank Al Maghrib"},
    {logoName: "bourse_de_casablanca.png", alt: "La bourse de casablanca"},
    {logoName: "bceao.png", alt: "Banque centrale des états de l'Afrique de l'ouest"},
    {logoName: "brvm.png", alt: "Bourse régionale des valeurs mobilières Afrique De l'Ouest"},
    {logoName: "cbe.png", alt: "Central bank of egypt"},
    {logoName: "egx.png", alt: "The egyptian exchange"},
    {logoName: "hcp.png", alt: "Haut commissariat du plan"},
    {logoName: "jse.png", alt: "JSE"},
    {logoName: "sarb.png", alt: "South African reserve bank"},
    {logoName: "trading_view.png", alt: "TradingView"},
    {logoName: "world_bank.png", alt: "World Bank"},
];


export default function Home() {
    const bg2 = useSelector((state) => state.themeReducer.backgroundColor2);
    const [isLargerThan] = useMediaQuery("(min-width: 800px)");
    const [loadMore, setLoadMore] = useState(true);
    const [articles, setArticles] = useState([]);
    const [partnerIndex, setPartnerIndex] = useState(0);
    const [autoRotate, setAutoRotate] = useState(true);

    const navigate = useNavigate();

    const handleLoadMore = () => {
        setLoadMore((prevLoadMore) => !prevLoadMore);
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await fetchArticlesTeasers();
                setArticles(result);
            } catch (error) {
                console.error("Error fetching article's teasers:", error);
            }
        };

        setTimeout(() => {
            fetchData();
        }, 2000);
    }, []);

    const handleDiscoverMore = () => {
        navigate("/articles");
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString();
        const formattedTime = date.toLocaleTimeString();
        return `${formattedDate}`;
    };
    const bgColor = useSelector(state => state.themeReducer.backgroundColor1)
    const choseColor = (cat) => {
        switch (cat) {
            case "Finance":
                return "blue"
            case "Fashion":
                return "red"
            case "Branding":
                return "green"
            case "Technology":
                return "yellow"
            case "Travel":
                return "purple"
            default :
                return "black"
        }
    }
    const handleGetStartedClick = () => {
        navigate("/wait-list");
    };

    const handleReadMore = (id) => {
        window.location.href = "/article/" + id
    };

    return (
        <>
            <Box bgColor={bg2}>
                <TradingViewTicker/>
                <Box
                    textAlign="left"
                    backgroundImage={xcapitalbanner}
                    backgroundColor={"rgba(0,0,0,0.5)"}
                    backgroundBlendMode={"multiply"}
                    color={"white"}
                    padding={"80px 40px 250px 80px"}
                    bgSize={"cover"}
                    bgRepeat={"no-repeat"}
                >
                        <Heading
                            as={"h1"}
                            fontSize={"42px"}
                            fontWeight={"800"}
                            marginBottom={"20px"}
                            maxWidth={"80%"}
                        >
                            Empower Your Investments with Advanced Analytics and Market Insights{" "}
                        </Heading>
                        <Text
                            fontSize={"24px"}
                            maxWidth={"80%"}
                        >
                            Join the growing number of investors, traders, and financial institutions using X Capital to
                            make data-driven investment decisions. Our platform offers advanced analytics and customized
                            financial tools to help you navigate today’s dynamic markets. Ready to gain a competitive
                            edge? Join us today and be the first to explore X Capital.
                        </Text>
                        <Button
                            bg={"white"}
                            mt={5}
                            width={"160px"}
                            height={"40px"}
                            borderRadius={"12px"}
                            fontSize={"20px"}
                            _hover={{backgroundColor:"rgb(110,110,110)", color:"white"}}
                            onClick={handleGetStartedClick}
                        >
                            Book a demo
                        </Button>

                </Box>
                <Box paddingTop={"40px"}></Box>
                <Swiper
                    modules={[Autoplay, Navigation]}
                    loop={true}
                    autoplay={{delay: 3000}}
                    speed={2000}
                    direction="horizontal"
                    breakpoints={{
                        768: {
                            direction: "horizontal",
                            slidesPerView: 3
                        },
                        0: {
                            direction: "vertical",
                            slidesPerView: 1
                        },
                    }}
                    style={{width: "100%", height: "250px"}}
                >
                    {partners.map((partner, index) => (
                        <SwiperSlide key={index}>
                            <Box padding={"10px"}>
                                <Box height={"200px"}
                                     background={`url('${partnersLogosPath + partner.logoName}') center/contain no-repeat`}>
                                </Box>
                            </Box>
                        </SwiperSlide>
                    ))}
                </Swiper>


                <ProductsSection/>
                <Flex
                    direction={"column"}
                    align={"center"}
                    justify={"center"}
                    gap={"12px"}
                    padding={"40px 0"}
                >
                    <Heading>Leading Articles in Finance</Heading>
                    <Text fontSize={"12px"}>
                        Explore our Insightful Articles for the latest financial trends and
                        expert opinions distilled into actionable insights.
                    </Text>
                    <Flex
                        gap={"12px"}
                        flexWrap={"wrap"}
                        justify={"center"}
                    >
                        {articles.slice(0, 4).map((article, index) => (
                            <Box key={index} flex="0 0 300px" onClick={() => handleReadMore(article.id)}
                                 bgColor={bgColor} paddingBottom={"5px"} cursor={"pointer"}>
                                <Image
                                    src={article.image_cover_url}
                                    alt={article.title}
                                    borderRadius="2px"
                                    h="172px"
                                    objectFit="cover"
                                    w="100%"
                                />
                                <Box padding="15px">
                                    <Text fontWeight={"500"} fontSize={"16px"}
                                          color={choseColor(article.category.name)}>
                                        {article.category.name}
                                    </Text>
                                    <Stack mt="4">
                                        <Heading fontSize="18px">{article.title}</Heading>
                                    </Stack>
                                    <Text mt="20px" fontWeight={"500"}
                                          color={"gray"}>{formatDate(article.created_at)}</Text>
                                </Box>
                            </Box>
                        ))}
                    </Flex>
                </Flex>
                <Center>
                    <Box display="block">
                        <Button
                            bg={"black"}
                            color={"white"}
                            width={"60"}
                            onClick={handleDiscoverMore}
                        >
                            Discover More
                        </Button>
                    </Box>
                </Center>

                <Center>
                    <Questions/>
                </Center>
            </Box>
        </>
    );
}
